import React, { useCallback } from 'react';

import { Control, UseFormWatch } from 'react-hook-form';
import CheckboxHF from 'common/components/FormHF/CheckboxHF';
import type { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FallbackFormValues } from 'utils/type-tools';
import { IFormValues } from 'common/components/FormHF/types';
import styles from './styles.module.scss';
import { HydraparkProjectFields } from '../../../../../containers/@hydrapark/project-fields';


interface IProps<TFormValues extends FallbackFormValues> {
    className?: string,
    control: Control<TFormValues>
    setValue: UseFormSetValue<TFormValues>,
    watch: UseFormWatch<TFormValues>,
}

// eslint-disable-next-line
const text = 'By submitting this order, you accept full responsibility for product use, including risks of damage, injury, or death. Verify component specifications before purchasing. Custom products are non-returnable and non-refundable. HydraPak Seals disclaims all warranties, express or implied, and limits liability to product replacement only. Misuse voids any claims. These terms are governed by Utah law. By placing this order, you agree to these terms.';

export const TosCheckboxConnected = ({
  watch, className, control, setValue,
}: IProps<IFormValues<HydraparkProjectFields>>) => {
  const value = watch('tosAgreed' as any);

  const handleSetCounter = useCallback((fieldName: string) => {
    setValue(fieldName as HydraparkProjectFields, !value as unknown as number);
  }, [setValue, value]);

  return (
    <CheckboxHF
      control={control}
      name="tosAgreed"
      className={className}
      value="tosAgreed"
      onChangeHandler={handleSetCounter}
      description={text}
    >
      Accept
      <span className={styles.required}>*</span>
    </CheckboxHF>
  );
};

export default TosCheckboxConnected;
