export enum HydraparkProjectFields {
    hpProductTypeAttribute = 'hpProductTypeAttribute',
    hpProfileAttribute = 'hpProfileAttribute',
    material1 = 'hpMaterial1Construction',
    hpCanFinish = 'hpCanFinish',
    material2 = 'hpMaterial2Construction',
    material3 = 'hpMaterial3Construction',
    part1_size1 = 'part1-size1',
    part2_size1 = 'part2-size1',
    part3_size1 = 'part3-size1',
    part1_size2 = 'part1-size2',
    part2_size2 = 'part2-size2',
    part3_size2 = 'part3-size2',
    notes = 'notes',
    // fake field
    tosAgreed = 'tosAgreed',
}
