import { useCallback, useMemo } from 'react';

import { useGenericFieldsActions, useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';

import { ISchemaDataStorage, IStorage, ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';
import type { IAxiosResponseError } from 'api/types';
import { FieldsType } from 'common/types/fields';

import { FallbackFormValues, StringKeys } from 'utils/type-tools';
import { FieldPath } from 'react-hook-form';
import {
  DefaultToFormAdaptersFieldType, IToFormAdapters, IToServiceAdapters, RecordUnionValueType,
} from './types';
import { defaultToServiceAdapters } from './constants';
import { useAdapters } from './hooks';
import { getUpdatedFieldToForm, getUpdatedFieldToService } from './utils';


export const useGenericFieldsDataAdopted = <TFormValues extends FallbackFormValues, TField = DefaultToFormAdaptersFieldType>(
  toFormAdapters?: IToFormAdapters<TField>,
  toServiceAdapters: IToServiceAdapters<StringKeys<TFormValues>> = defaultToServiceAdapters as IToServiceAdapters<StringKeys<TFormValues>>,
) => {
  const { toForm } = useAdapters<TField, TFormValues>(toFormAdapters, toServiceAdapters as IToServiceAdapters<StringKeys<FallbackFormValues>>);
  const {
    error, isLoading, order, fields,
  } = useGenericFieldsData<TFormValues>();

  const preparedData = useMemo<IStorage<keyof TFormValues, TField, IAxiosResponseError<any>>['fields']>(() => {
    const result = Object.entries(fields).reduce((acc, [fieldKey, field]) => {
      acc[fieldKey as keyof TFormValues] = getUpdatedFieldToForm<
          TField, TFormValues
      >(
          field as RecordUnionValueType<ISchemaDataStorage<FieldPath<TFormValues>>['fields']>,
          toForm,
      );
      return acc;
    }, {} as IStorage<keyof TFormValues, TField, IAxiosResponseError<any>>['fields']);
    return result;
  }, [toForm, fields]);

  return {
    error,
    isLoading,
    order,
    fields: preparedData,
  };
};

export const useGenericFieldsActionsAdopted = <TFormValues extends FallbackFormValues, TField = DefaultToFormAdaptersFieldType>(
  toFormAdapters?: IToFormAdapters<TField>,
  toServiceAdapters: IToServiceAdapters<StringKeys<TFormValues>> = defaultToServiceAdapters as IToServiceAdapters<StringKeys<TFormValues>>,
) => {
  const { toService, toForm } = useAdapters<TField, TFormValues>(
    toFormAdapters,
      toServiceAdapters as IToServiceAdapters<StringKeys<FallbackFormValues>>,
  );
  const { getFieldsByName, updateFieldsState } = useGenericFieldsActions<TFormValues>();
  const { fields } = useGenericFieldsData<TFormValues>();

  const getFieldsByNameAdopted:
    (fieldsNames: Array<keyof TFormValues>) => ConfiguredRecordType<keyof TFormValues, TField> = useCallback(
      (fieldsNames: Array<keyof TFormValues>) => {
        const neededFields = getFieldsByName(fieldsNames as StringKeys<TFormValues>[]);
        return Object.entries(neededFields).reduce((acc, [fieldKey, field]) => {
          acc[fieldKey as keyof TFormValues] = getUpdatedFieldToForm<
              TField, TFormValues
          >(
              field as RecordUnionValueType<ISchemaDataStorage<FieldPath<TFormValues>>['fields']>,
              toForm,
          );
          return acc;
        }, {} as ConfiguredRecordType<keyof TFormValues, TField>);
      },
      [getFieldsByName, toForm],
    );

  /**
   * @name updateFieldsStateAdopted
   * @description data adapter to change format of the changed field value in form to required format of GenericFieldsHandlingLayer
   */
  const updateFieldsStateAdopted = useCallback((fieldType: FieldsType, fieldName: StringKeys<TFormValues>, fieldVal?: TFormValues[keyof TFormValues]) => {
    const adoptedField = getUpdatedFieldToService<TFormValues>(fieldType, fieldName, fields[fieldName], toService, fieldVal);
    const push = adoptedField ? [adoptedField] : [];
    /** const { [fieldName]: remove, ...others } = fields;
    console.log('removed', remove);
    console.log('others', others);
    console.log('new', adoptedField);
    const format = Object.values(others).map((field) => getStoredFieldToService(
      field,
      toService,
    ));

    console.log('format', format); * */
    updateFieldsState([...push]); // NOTE: here we can append all fields state if needed
  }, [fields, toService, updateFieldsState]);

  return {
    getFieldsByName: getFieldsByNameAdopted,
    updateFieldsState: updateFieldsStateAdopted,
  };
};
