import type { PredefinedContextType } from 'containers/GenericFieldsHandlingLayer/context';
import {
  CheckboxFieldStateType,
  DropdownFieldStateType,
  FieldsType,
  InputFieldStateType,
  RadioFieldStateType,
} from 'common/types/fields';
import { FallbackFormValues, StringKeys } from 'utils/type-tools';

export const generateFieldNameByGroup = (baseName: string, subName: string) => `${baseName}_${subName}`;

export const prepareDefaultFormData = <TFormValues extends FallbackFormValues>(
  fields: PredefinedContextType<StringKeys<TFormValues>>['data']['fields'],
): TFormValues => {
  const form: TFormValues = {} as TFormValues;
  Object.entries(fields).forEach(([fieldName, _fieldVal]) => {
    const fieldVal = _fieldVal as InputFieldStateType | DropdownFieldStateType | CheckboxFieldStateType | RadioFieldStateType;
    if (fieldVal) {
      switch (fieldVal.type) {
        case FieldsType.input: {
          form[fieldName as StringKeys<TFormValues>] = fieldVal.value?.selected?.value as TFormValues[StringKeys<TFormValues>];
          break;
        }
        case FieldsType.dropdown: {
          form[fieldName as StringKeys<TFormValues>] = (fieldVal?.value?.selected && fieldVal?.value?.selected[0]) as TFormValues[StringKeys<TFormValues>];
          break;
        }
        case FieldsType.checkboxes: {
          fieldVal.value?.selected
            ?.forEach((option) => {
              form[generateFieldNameByGroup(fieldName, option.id) as StringKeys<TFormValues>] = option.id as TFormValues[StringKeys<TFormValues>];
            });
          break;
        }
        case FieldsType.radioGroup: {
          form[fieldName as StringKeys<TFormValues>] = (fieldVal?.value?.selected && fieldVal.value?.selected[0].id) as TFormValues[StringKeys<TFormValues>];
          break;
        }
        default: break;
      }
    }
  });
  return form;
};
