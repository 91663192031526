import React from 'react';
import classNames from 'classnames';

import { WithChildren } from 'common/types';

import { ICustomOptionProps } from 'common/components/FieldGenerator/types';

import emptyImage from 'assets/empty-image-without-border.svg';
import styles from './styles.module.scss';


export interface IAccessoriesRadioButtonProps extends ICustomOptionProps {
  length?: string,
}

const RadioButton = ({
  option,
}: WithChildren<IAccessoriesRadioButtonProps>) => (
  <div>
    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
    <label htmlFor={option.id} style={{ backgroundImage: `url(${option.attributes?.imagea || emptyImage})` }} className={styles.image} />
    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
    <label
      htmlFor={option.id}
      className={classNames(styles.radioButton)}
    >
      {option.title}
    </label>
  </div>
);

export default RadioButton;
