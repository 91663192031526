/* eslint-disable max-len */
import { IServerItemOption } from 'common/types/fields';
import rawPrintStyle from 'utils/print';
import { LengthType } from 'common/types/additional';

const LogoUrl = '/@hydrapark-logo-2.png';

export const ItemTemplate = ({
  title, image, label, className, description,
}: { title?: string, image?: string, className?: string, label: string, description?: string }) => `
   <div class="item ${className}">
    <a>${label}</a>
    <div>
      ${image ? `<img src="${image}" alt="" />` : ''}
      <div>${title}</div>
    </div>
    ${description ? `<div class="description">${description}</div>` : ''}
  </div>
`;

export const DocumentTemplate = ({
  product,
  parts,
  profile,
  notes,
}: {
        product?: IServerItemOption,
        profile?: IServerItemOption,
        parts: Array<{material?: IServerItemOption, size1?: number, size2?: number, unit: LengthType}>
        notes?: string,
    }, quantity: number) => {
  const items = [
    ItemTemplate({
      title: product?.title,
      description: product?.attributes?.description,
      label: 'Product Type',
    }),
    ItemTemplate({
      title: profile?.title,
      image: profile?.attributes?.imagea,
      description: profile?.attributes?.description,
      label: 'Profile',
    }),
    ...parts.map((part, index) => ItemTemplate({
      title: part.unit === LengthType.inches ? (`${part.size1 ?? 'N.A.'}" ID ${part.size2 ?? 'N.A.'}" OD`) : (`${part.size1 ?? 'N.A.'}cm ID ${part.size2 ?? 'N.A.'} cm OD`),
      label: `Material #${index + 1}: ${part.material?.title!}`,
    })),
    notes ? ItemTemplate({ title: notes, label: 'Notes' }) : '',
    quantity ? ItemTemplate({ title: quantity.toFixed(0), label: 'Assembly Qty' }) : '',
  ];
  return `<html>
    <head>
        <style>
            ${rawPrintStyle}
        </style>
    </head>
    <body class="print">
        <div class="print-body">
            <img src="https://${window.location.host}${LogoUrl}" class="logo" />
            <h1>Your Assembly</h1>
            <p>By submitting this order, you accept full responsibility for product use, including risks of damage, injury, or death. Verify component specifications before purchasing. Custom products are non-returnable and non-refundable. HydraPak Seals disclaims all warranties, express or implied, and limits liability to product replacement only. Misuse voids any claims. These terms are governed by Utah law. By placing this order, you agree to these terms.</p>
            ${items.join('')}
        </div>
    </body>
</html>`;
};
