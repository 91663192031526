import React from 'react';
import {
  Control,
  Controller, FieldPath,
  RegisterOptions,
} from 'react-hook-form';

import RadioButton, { IRadioButtonProps } from 'common/components/RadioButton';
import { FallbackFormValues } from 'utils/type-tools';

export interface IRadioButtonHFProps<TFormValue extends FallbackFormValues> extends IRadioButtonProps {
  name: FieldPath<TFormValue>,
  control: Control<TFormValue>,
  rules?: RegisterOptions,
  value: string,
  image?: string,
  onChangeHandler?: (fieldVal: string) => void,
}

const RadioButtonHF = <TFormValue extends FallbackFormValues = FallbackFormValues>({
  name,
  label,
  control,
  rules,
  onChangeHandler,
  ...props
}: IRadioButtonHFProps<TFormValue>) => (
  <Controller
    name={name}
    control={control}
    rules={{ ...rules }}
    render={({
      field,
    }) => (
      <RadioButton
        {...props}
        onChange={() => {
          // if current radio btn already has selected - unset them (looks like on change is not working for this case,
          // so used onClick handler
          const result = field.value && field.value === props.value ? '' : props.value;
          if (onChangeHandler) {
            onChangeHandler(result);
          }
          field.onChange(result);
        }}
        onClick={() => {
          if (field.value && field.value === props.value) {
            field.onChange('');
            if (onChangeHandler) {
              onChangeHandler('');
            }
          }
        }}
        checked={field.value === props.value}
        name={field.name}
        label={label}
      />
    )}
  />
  );

// @ts-ignore
export default RadioButtonHF;
