import type { ISelectedFieldData, UnitedFieldState } from 'common/types/fields';
import type { IAxiosResponseError } from 'api/types';
import type { ISchemaDataStorage, IWholeSchemaWithState } from './types';

type DataFormatHandlerType = (data: IWholeSchemaWithState['state'], schema: IWholeSchemaWithState['schema']) => ({
  order: ISchemaDataStorage<string>['order'],
  fields: ISchemaDataStorage<string>['fields'],
});
type FieldsUpdateHandlerType = (fields: ISchemaDataStorage<string>['fields'], updated: Array<UnitedFieldState>) => ISchemaDataStorage<string>['fields'];

export const formatSchemaDataToStore: DataFormatHandlerType = (data, schema) => {
  const order: ISchemaDataStorage<string>['order'] = [];
  const fields: any = {};
  schema.forEach((fieldSchema) => {
    order.push(fieldSchema.name);
    const appropriateState = data.find((fieldData) => fieldData.name === fieldSchema.name);
    fields[fieldSchema.name] = {
      ...fieldSchema,
      ...(appropriateState ?? {}),
    };
  });

  return {
    order,
    fields,
  };
};

export const mergeUpdatedFieldsWithCurrentState: FieldsUpdateHandlerType = (
  fields,
  updated,
) => {
  const updatedFields: any = {
    ...fields,
  };

  updated.forEach((fieldUpdated) => {
    updatedFields[fieldUpdated.name] = {
      ...updatedFields[fieldUpdated.name],
      ...fieldUpdated,
    };
  });

  return updatedFields;
};

export const isWholeSchemaError = (error: IAxiosResponseError<unknown>): error is IAxiosResponseError<IWholeSchemaWithState> => (
  !!(error as IAxiosResponseError<IWholeSchemaWithState>).data?.errors?.schema
    || !!(error as IAxiosResponseError<IWholeSchemaWithState>).data?.errors?.state
    || !!(error as IAxiosResponseError<IWholeSchemaWithState>).data?.errors?.session
    || (error as IAxiosResponseError<IWholeSchemaWithState>).data?.errors === undefined
);

export const isUpdateFieldsError = (error: IAxiosResponseError<unknown>): error is IAxiosResponseError<Array<ISelectedFieldData>> => (
  !!(error as IAxiosResponseError<Array<ISelectedFieldData>>).data?.errors
    && !!(error as IAxiosResponseError<Array<ISelectedFieldData>>).data?.errors!.length
    && !!(error as IAxiosResponseError<Array<ISelectedFieldData>>).data?.errors![0].fieldId
    && !!(error as IAxiosResponseError<Array<ISelectedFieldData>>).data?.errors![0].message
);
