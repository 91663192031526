import type { IServerFieldStateOptions } from 'common/types/fields';
import type { OptionType } from 'common/components/Select/types';

import emptyImageMobile from 'assets/empty-image-square.svg';
import emptyImageSelect from 'assets/empty-image-without-border.svg';
import type { DefaultToFormAdaptersFieldType, IToFormAdapters, IToServiceAdapters } from './types';


export const adoptCheckboxToService: Required<IToServiceAdapters<string>>['checkboxToServiceAdapter'] = (
  fieldId: string,
  options: IServerFieldStateOptions | undefined,
  checkboxValues?: string[],
) => ({
  fieldId,
  value: checkboxValues?.map((id) => (id)),
});

export const adoptRadioToService: Required<IToServiceAdapters<string>>['radioToServiceAdapter'] = (
  fieldId: string,
  options: IServerFieldStateOptions | undefined,
  radioValue?: string,
) => ({
  fieldId,
  value: radioValue,
});

export const adoptDropdownToService: Required<IToServiceAdapters<string>>['dropdownToServiceAdapter'] = (
  fieldId: string,
  options: IServerFieldStateOptions | undefined,
  dropdownValue?: OptionType,
) => ({
  fieldId,
  value: dropdownValue?.id,
});

export const adoptInputToService: Required<IToServiceAdapters<string>>['inputToServiceAdapter'] = (fieldId: string, inputValue?: string | number) => ({
  fieldId,
  value: `${inputValue ?? ''}`,
});

export const adoptInputToForm: Required<IToFormAdapters<DefaultToFormAdaptersFieldType>>['inputToFormAdapter'] = (field) => field;

export const adoptDropdownToForm: Required<IToFormAdapters<DefaultToFormAdaptersFieldType>>['dropdownToFormAdapter'] = (field) => {
  // generated dropdown know nothing about whether we need to render empty img or not. So we have to render empty img
  // only if there are at least one option from BE contains not empty image link
  const containsImg = !!field.value?.options?.find((option) => !!option.attributes?.image);
  return {
    ...field,
    options: field.value?.options?.map((option) => ({
      ...option,
      image: containsImg ? option.attributes?.image || emptyImageSelect : undefined,
    })),
  };
};

export const adoptCheckboxToForm: Required<IToFormAdapters<DefaultToFormAdaptersFieldType>>['checkboxToFormAdapter'] = (field) => {
  // generated dropdown know nothing about whether we need to render empty img or not. So we have to render empty img
  // only if there are at least one option from BE contains not empty image link
  const containsImg = !!field.value?.options?.find((option) => !!option.attributes?.image);
  return {
    ...field,
    options: field.value?.options?.map((option) => ({
      ...option,
      image: containsImg ? option.attributes?.image || emptyImageMobile : undefined,
    })),
  };
};

export const adoptRadioToForm: Required<IToFormAdapters<DefaultToFormAdaptersFieldType>>['radioToFormAdapter'] = (field) => {
  // generated dropdown know nothing about whether we need to render empty img or not. So we have to render empty img
  // only if there are at least one option from BE contains not empty image link
  const containsImg = !!field.value?.options?.find((option) => !!option.attributes?.image);
  return {
    ...field,
    options: field.value?.options?.map((option) => ({
      ...option,
      image: containsImg ? option.attributes?.image || emptyImageMobile : undefined,
    })),
  };
};
