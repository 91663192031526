import ProductSelectorView from 'views/@hydrapark/ProductSelectorView';
import ProfileSelectorView from 'views/@hydrapark/ProfileSelectorView';
import MaterialSelectorView from 'views/@hydrapark/MaterialSelectorView';
import MeasurmentsView from 'views/@hydrapark/MeasurmentsView';
import SummaryView from 'views/@hydrapark/SummaryView';
import type { IStepView } from './types';
/**
 * This is a project specific import. When switching projects, look for every import that is marked with @PROJECT
 * This one is already in the specific project folder so needs no changing, so not marked with @PROJECT
 */
import {
  STEP_DIMENSIONS, STEP_MATERIALS, STEP_PRODUCT, STEP_PROFILE,
} from './stepNames';
import { HydraparkProjectFields } from '../project-fields';

export const VIEWS_LIST: Array<IStepView> = [{
  title: 'Select Product',
  stepName: STEP_PRODUCT,
  component: ProductSelectorView,
  description: 'Please use the below options to select the product type you are looking for.',
  requiredFields: [
    HydraparkProjectFields.hpProductTypeAttribute,
  ],
}, {
  title: 'Select Profile',
  stepName: STEP_PROFILE,
  component: ProfileSelectorView,
  description: 'Please use the below options to select the profile you are looking for.',
  requiredFields: [
    HydraparkProjectFields.hpProfileAttribute,
  ],
},
{
  title: 'Select Material',
  stepName: STEP_MATERIALS,
  component: MaterialSelectorView,
  description: 'Please use the below drop-down option to select material you need.',
  requiredFields: [
    HydraparkProjectFields.material1,
    HydraparkProjectFields.material2,
    HydraparkProjectFields.material3,
    HydraparkProjectFields.hpCanFinish,
  ],
},
{
  title: 'Measurements',
  stepName: STEP_DIMENSIONS,
  component: MeasurmentsView,
  description: 'Please use the below inputs to specify the measurements you need.',
  requiredFields: [
    HydraparkProjectFields.part1_size1,
    HydraparkProjectFields.part1_size2,
    HydraparkProjectFields.part2_size1,
    HydraparkProjectFields.part2_size2,
    HydraparkProjectFields.part3_size1,
    HydraparkProjectFields.part3_size2,
  ],
}, {
  title: 'Confirm Your Assembly',
  stepName: 'Confirm Assembly',
  component: SummaryView,
  // eslint-disable-next-line
    description: 'Please verify component selections, specifications, and assembly length for your application. Custom assemblies are non-returnable and non-refundable. To modify, click the item below.',
  requiredFields: [
    HydraparkProjectFields.notes,
  ],
}];

export const DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION = 'There are no results that match your criteria. Please select other values.';
