import React from 'react';
import {
  Control, FieldPath,
  RegisterOptions,
  UseFormWatch,
} from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { UseFormGetFieldState, UseFormSetValue } from 'react-hook-form/dist/types/form';

import type { IServerItemOption } from 'common/types/fields';

import { IRadioButtonProps } from 'common/components/RadioButton';
import RadioButtonHF from 'common/components/FormHF/RadioButtonHF';
import ErrorMessage from 'common/components/ErrorMessage';
import { ICustomOptionProps } from 'common/components/FieldGenerator/types';
import { FallbackFormValues } from 'utils/type-tools';
import { ChangeHandlerType } from '../types';

import styles from './styles.module.scss';


interface IRadioButtonsListProps<TFormValue extends FallbackFormValues> extends IRadioButtonProps {
  name: FieldPath<TFormValue>,
  optionsList: Array<IServerItemOption>,
  selectedList?: Array<IServerItemOption>,
  control: Control<TFormValue>,
  rules?: RegisterOptions<Record<string, any>>,
  watch: UseFormWatch<TFormValue>,
  setValue: UseFormSetValue<TFormValue>,
  getFieldState: UseFormGetFieldState<TFormValue>,
  onChangeHandler?: ChangeHandlerType<TFormValue>,
  renderOptionLabelComponent?: () => (React.FC<ICustomOptionProps> | undefined),
}

const RadioButtonsList = <TFormValue extends FallbackFormValues = FallbackFormValues>({
  name,
  optionsList,
  control,
  rules,
  watch,
  setValue,
  selectedList,
  getFieldState,
  onChangeHandler,
  renderOptionLabelComponent,
  ...props
}: IRadioButtonsListProps<TFormValue>) => {
  const fieldsState = getFieldState(name);

  const onChangeHandlerWrapper = (fieldVal: string) => {
    if (onChangeHandler) {
      onChangeHandler(fieldVal as TFormValue[keyof TFormValue]);
    }
  };

  const RenderComponent = renderOptionLabelComponent && renderOptionLabelComponent();

  return (
    <>
      {optionsList.map((option) => (
        RenderComponent
          ? (
            <RadioButtonHF
              key={option.id}
              name={name}
              value={option.id}
              label={option.title}
              image={option.attributes?.image}
              control={control}
              onChangeHandler={onChangeHandlerWrapper}
              disabled={props.disabled}
              rules={rules}
              className={styles.item}
            >
              <RenderComponent option={option} />
            </RadioButtonHF>
          )
          : (
            <div key={option.id}>
              <RadioButtonHF
                name={name}
                value={option.id}
                label={option.title}
                control={control}
                onChangeHandler={onChangeHandlerWrapper}
                disabled={props.disabled}
                rules={rules}
                className={styles.item}
              />
            </div>
          )
      ))}
      {fieldsState.error && <ErrorMessage text={fieldsState.error as unknown as string} />}
    </>
  );
};

export default RadioButtonsList;
