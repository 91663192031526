import { ConfiguredRecordType, ISchemaDataStorage } from 'containers/GenericFieldsHandlingLayer/types';
import { GeneratorFieldType } from 'common/components/FieldGenerator/types';
import { ExtendedDataStorageType } from 'containers/AdditionalDataStorage/types';
import { LengthType } from 'common/types/additional';
import { toFixedByLengthType } from 'views/@hydrapark/MeasurmentsView/utils';
import {
  getSingleNumberValue, getSingleOption, getSingleStringValue, isCPQData, wrapData,
} from '../utils';
import { HydraparkProjectFields } from '../../../containers/@hydrapark/project-fields';

export const partVariables = [
  {
    material: HydraparkProjectFields.material1,
    size1: HydraparkProjectFields.part1_size1,
    size2: HydraparkProjectFields.part1_size2,
  },
  {
    material: HydraparkProjectFields.material2,
    size1: HydraparkProjectFields.part2_size1,
    size2: HydraparkProjectFields.part2_size2,
  },
  {
    material: HydraparkProjectFields.material3,
    size1: HydraparkProjectFields.part3_size1,
    size2: HydraparkProjectFields.part3_size2,
  },
];
export const mapProjectFieldsToProjectHydraparkCart = (
  fields: ConfiguredRecordType<HydraparkProjectFields, GeneratorFieldType>,
  additional: ExtendedDataStorageType,
) => {
  const productType = getSingleOption(fields.hpProductTypeAttribute?.value?.selected);
  const profile = getSingleOption(fields.hpProfileAttribute?.value?.selected);
  const notes = getSingleStringValue(fields.notes?.value?.selected);
  const parts = partVariables.map((part) => ({
    material: getSingleOption(fields[part.material]?.value?.selected)?.title,
    size1: fields[part.size1]?.value?.selected
      ? toFixedByLengthType(getSingleNumberValue(fields[part.size1]?.value?.selected)!, LengthType.inches, additional.lengthType)
      : undefined,
    size2: fields[part.size2]?.value?.selected
      ? toFixedByLengthType(getSingleNumberValue(fields[part.size2]?.value?.selected)!, LengthType.inches, additional.lengthType)
      : undefined,
    unit: additional.lengthType,
  })).filter((part) => part.material);

  // eslint-disable-next-line max-len
  const generatedConfigId = `${productType?.title}:${profile?.title}:${parts.map((part) => `${part.material}:${part.size1 ?? 'N.A.'}-${part.size2 ?? 'N.A.'} ${part.unit}`).join(',')}`;

  return {
    generatedConfigId,
    quantity: additional.configQuantity,
    productType: productType?.title,
    profile: profile?.title,
    parts,
    notes,
  };
};

export const sendDataToParentIframe = async (data: ISchemaDataStorage<HydraparkProjectFields>['fields'], additional: ExtendedDataStorageType) => {
  console.log('sendDataToParentIframe', wrapData(mapProjectFieldsToProjectHydraparkCart(data, additional))); // eslint-disable-line no-console
  if (window.self !== window.parent) {
    return new Promise<void>((resolve, reject) => {
      window.parent.postMessage(wrapData(mapProjectFieldsToProjectHydraparkCart(data, additional)), '*');

      const listenToResponse = (e: MessageEvent) => {
        if (isCPQData(e.data)) {
          const { error, isLoading } = e.data;

          if (!isLoading) {
            if (!error) {
              resolve();
            } else {
              reject(new Error(error));
            }

            window.removeEventListener('message', listenToResponse);
          }
        }
      };

      window.addEventListener('message', listenToResponse);
    });
  }
  throw new Error('Can only send data to parent iframe being in iframe');
};
