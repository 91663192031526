import React, { useCallback, useMemo } from 'react';
import {
  Control, FieldPath,
  RegisterOptions,
  UseFormWatch,
} from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { UseFormGetFieldState, UseFormSetValue } from 'react-hook-form/dist/types/form';

import type { IServerItemOption } from 'common/types/fields';

import { ICheckboxProps } from 'common/components/Checkbox';
import CheckboxHF from 'common/components/FormHF/CheckboxHF';
import ErrorMessage from 'common/components/ErrorMessage';
import { isRuleMessage } from 'utils/isRuleMessage';
import { FallbackFormValues } from 'utils/type-tools';
import { generateFieldNameByGroup } from '../utils';
import { ChangeHandlerType } from '../types';


interface ICheckboxesListProps<TFormValue extends FallbackFormValues> extends ICheckboxProps {
  name: FieldPath<TFormValue>,
  optionsList: Array<IServerItemOption>,
  selectedList?: Array<IServerItemOption>,
  control: Control<TFormValue>,
  rules?: RegisterOptions<Record<string, any>>,
  watch: UseFormWatch<TFormValue>,
  setValue: UseFormSetValue<TFormValue>,
  getFieldState: UseFormGetFieldState<TFormValue>,
  onChangeHandler?: ChangeHandlerType<TFormValue>,
}

const CheckboxesList = <TFormValue extends FallbackFormValues = FallbackFormValues>({
  name,
  optionsList,
  control,
  rules,
  watch,
  setValue,
  selectedList,
  getFieldState,
  onChangeHandler,
  ...props
}: ICheckboxesListProps<TFormValue>) => {
  const namesList = useMemo(
    () => optionsList.map((option) => generateFieldNameByGroup(name, option.id) as FieldPath<TFormValue>),
    [optionsList, name],
  );
  const watchedVars = watch(namesList);
  const fieldsState = namesList.map((checkboxName) => getFieldState(checkboxName));
  const { error } = fieldsState[0];
  const errorMessage = error?.message;

  const onChangeHandlerWrapper = useCallback((fieldName: string, fieldVal: string, isSelected: boolean) => {
    const checkboxesValues = watchedVars.reduce((acc: Array<string>, checkboxVal: string) => {
      if (checkboxVal && checkboxVal !== fieldVal) {
        acc.push(checkboxVal);
      }
      return acc;
    }, []);
    if (isSelected) {
      checkboxesValues.push(fieldVal);
    }
    // const index = namesList.findIndex((item) => item === name);
    // watchedVars[index];
    // const values: string[] = [];
    // watchedVars?.forEach((item) => {
    //   if (item !== fieldVal) {
    //     values.push(item as string);
    //   }
    // });

    if (onChangeHandler) {
      onChangeHandler(checkboxesValues as TFormValue[keyof TFormValue]);
    }
  }, [watchedVars, onChangeHandler]);

  return (
    <div className="checkboxesList">
      {optionsList.map((option, index) => (
        <div key={namesList[index]}>
          <CheckboxHF
            name={namesList[index]}
            value={option.id}
            label={option.title}
            image={option.attributes?.image}
            description={option.attributes?.description}
            control={control}
            onChangeHandler={onChangeHandlerWrapper}
            disabled={props.disabled}
            rules={{
              validate: () => {
                if (rules?.required) {
                  if (!watchedVars.length || !watchedVars.find((item) => !!item)) {
                    return isRuleMessage(rules.required)
                      ? rules.required.message
                      : rules.required;
                  }
                }
                return true;
              },
            }}
          />
        </div>
      ))}
      {errorMessage && <ErrorMessage text={errorMessage} />}
    </div>
  );
};

export default CheckboxesList;
