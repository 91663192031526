import React, { useMemo } from 'react';

import { SelectedConfiguration } from 'common/types/additional';
import { CardStatus } from 'common/components/CardNav/constants';
import { useActiveSelectedConfiguration } from 'containers/AdditionalDataStorage/context';
import { useGenericFieldsActionsAdopted } from 'containers/FormDataAdapter';

import SecondaryNav from 'containers/@hydrapark/components/SecondaryNav';

import emptyImage from 'assets/empty-image-without-border.svg';
import { OptionType } from '../../../../common/components/Select/types';
import { HydraparkProjectFields } from '../../project-fields';
import { IFormValues } from '../../../../common/components/FormHF/types';


const SecondaryNavWrapper = () => {
  const { getFieldsByName } = useGenericFieldsActionsAdopted<IFormValues<HydraparkProjectFields>>();
  const fields = useMemo(
    () => getFieldsByName([
      HydraparkProjectFields.hpProductTypeAttribute,
      HydraparkProjectFields.hpProfileAttribute,
    ]),
    [getFieldsByName],
  );
  const activeSelectedConfiguration = useActiveSelectedConfiguration();

  const profileSelected = fields[HydraparkProjectFields.hpProfileAttribute]?.value?.selected as OptionType[] | undefined;
  const prodileNav = useMemo(() => {
    const hoseSelected = profileSelected;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.profile,
      status: hoseSelected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImage,
      emptyBodyLabel: !hoseSelected ? 'Profile' : undefined,
      selectedItem: hoseSelected ? {
        image: hoseSelected[0].attributes?.imagea!,
        title: hoseSelected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, profileSelected]);

  const productSelected = fields[HydraparkProjectFields.hpProductTypeAttribute]?.value?.selected as OptionType[] | undefined;
  const productTypeNav = useMemo(() => {
    const fitting1Selected = productSelected;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.profile,
      status: fitting1Selected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImage,
      emptyBodyLabel: !fitting1Selected ? 'Product Type' : undefined,
      selectedItem: fitting1Selected ? {
        image: fitting1Selected[0].attributes?.imagea!,
        title: fitting1Selected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, productSelected]);

  return (
    <SecondaryNav
      profile={productTypeNav}
      productType={prodileNav}
    />
  );
};

export default SecondaryNavWrapper;
