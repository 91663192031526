import { useMemo } from 'react';
import { FallbackFormValues, StringKeys } from 'utils/type-tools';
import { DefaultToFormAdaptersFieldType, IToFormAdapters, IToServiceAdapters } from './types';
import { defaultToFormAdapters, defaultToServiceAdapters } from './constants';


export const useAdapters = <
    TField = DefaultToFormAdaptersFieldType,
    TFormValues extends FallbackFormValues = FallbackFormValues>
  (
    toFormAdapters?: IToFormAdapters<TField>,
    toServiceAdapters: IToServiceAdapters<StringKeys<FallbackFormValues>> = defaultToServiceAdapters,
  ) => {
  const preparedToFormAdapters = useMemo<IToFormAdapters<TField>>(() => ({
    checkboxToFormAdapter: toFormAdapters?.checkboxToFormAdapter
      ?? defaultToFormAdapters.checkboxToFormAdapter as IToFormAdapters<TField>['checkboxToFormAdapter'],
    radioToFormAdapter: toFormAdapters?.radioToFormAdapter
      ?? defaultToFormAdapters.radioToFormAdapter as IToFormAdapters<TField>['radioToFormAdapter'],
    dropdownToFormAdapter: toFormAdapters?.dropdownToFormAdapter
      ?? defaultToFormAdapters.dropdownToFormAdapter as IToFormAdapters<TField>['dropdownToFormAdapter'],
    inputToFormAdapter: toFormAdapters?.inputToFormAdapter
      ?? defaultToFormAdapters.inputToFormAdapter as IToFormAdapters<TField>['inputToFormAdapter'],
  }), [toFormAdapters]);

  const preparedToServiceAdapters = useMemo<Required<IToServiceAdapters<StringKeys<TFormValues>>>>(() => ({
    checkboxToServiceAdapter: toServiceAdapters?.checkboxToServiceAdapter
      ?? defaultToServiceAdapters.checkboxToServiceAdapter,
    radioToServiceAdapter: toServiceAdapters?.radioToServiceAdapter
      ?? defaultToServiceAdapters.radioToServiceAdapter,
    dropdownToServiceAdapter: toServiceAdapters?.dropdownToServiceAdapter
      ?? defaultToServiceAdapters.dropdownToServiceAdapter,
    inputToServiceAdapter: toServiceAdapters?.inputToServiceAdapter
      ?? defaultToServiceAdapters.inputToServiceAdapter,
  }), [toServiceAdapters]);

  return {
    toForm: preparedToFormAdapters,
    toService: preparedToServiceAdapters,
  };
};
