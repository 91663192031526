import React from 'react';

import { ICard } from 'common/components/CardNav/types';

import CardNav from 'common/components/CardNav';

import styles from './styles.module.scss';

interface ISecondaryNavProps {
    profile: ICard,
    productType: ICard,
}

const SecondaryNav = ({
  profile,
  productType,
}: ISecondaryNavProps) => (
  <div className={styles.secondaryNav}>
    <CardNav
      active={profile.active}
      status={profile.status}
      selectedItem={profile.selectedItem}
      emptyBodyLabel={profile.emptyBodyLabel}
      defaultImage={profile.defaultImage}
    />
    <CardNav
      active={productType.active}
      status={productType.status}
      selectedItem={productType.selectedItem}
      emptyBodyLabel={productType.emptyBodyLabel}
      defaultImage={productType.defaultImage}
    />
  </div>
);

export default SecondaryNav;
