import { useMutation } from '@tanstack/react-query';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { DocumentTemplate } from './printer';
import { CONTACT_EMAIL } from '../../../common/constants';
import styles from './styles.module.scss';
import mailIcon from '../../../assets/icons/mail.svg';
import printIcon from '../../../assets/icons/print.svg';

import { Summary } from './useSummary';
import { printPDF } from '../../../api/requestFuncs';
import { useSessionId } from '../../../containers/GenericFieldsHandlingLayer/context';

export const emailLink = (pdfUrl: string) => {
  const subject = 'Custom Assembly Configuration';
  const body = `${pdfUrl}`;

  return `mailto:${CONTACT_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
};
export const PrintSection = ({ summary }: { summary: Summary }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [pdfError, setPdfError] = useState<string>('');
  const sessionId = useSessionId();
  const {
    mutate,
    isLoading: isPDFLoading,
  } = useMutation(['generate-pdf', summary.generatedConfigId, summary.quantity], async () => {
    const html = DocumentTemplate(summary, summary.quantity);

    const url = await printPDF(sessionId!, html);

    return url;
  });

  const onEmail = useCallback(async () => {
    setPdfUrl('');
    setPdfError('');
    const win = window.open('', 'Generate PDF');
    if (win) {
      win.document.body.innerHTML = 'Generating PDF...';
    }
    try {
      await mutate(undefined, {
        onSuccess: (url) => {
          if (win) {
            win.location = emailLink(url);
          } else {
            window.open(emailLink(url), '_blank');
          }
          setPdfUrl(url);
        },
        onError: (e) => {
          setPdfError(String(e));
          win?.close();
        },
      });
    } catch (e) {
      setPdfError(String(e));
      win?.close();
    }
  }, [summary.generatedConfigId, summary.quantity, summary.notes]);

  const onDownload = useCallback(async () => {
    setPdfUrl('');
    setPdfError('');
    const win = window.open('', 'Generate PDF');
    if (win) {
      win.document.body.innerHTML = 'Generating PDF...';
    }
    try {
      await mutate(undefined, {
        onSuccess: (url) => {
          if (win) {
            win.location = url;
          } else {
            window.open(url, '_blank');
          }
          setPdfUrl(url);
        },
        onError: (e) => {
          setPdfError(String(e));
          win?.close();
        },
      });
    } catch (e) {
      setPdfError(String(e));
      win?.close();
    }
  }, [summary.generatedConfigId, summary.quantity, summary.notes]);

  useEffect(() => {
    setPdfUrl('');
    setPdfError('');
  }, [summary.generatedConfigId, summary.quantity, summary.notes]);


  const mailTo = useMemo(() => emailLink(pdfUrl), [pdfUrl]);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.title = summary.generatedConfigId;
      iframeRef.current.srcdoc = DocumentTemplate(summary, summary.quantity);
    }
  }, [summary.generatedConfigId, summary.quantity, summary.notes]);

  return (
    <>
      <iframe title="Test" ref={iframeRef} style={{ width: '100vw', height: '100vh', display: 'none' }} />
      <div className={styles.contact}>
        {/* eslint-disable-next-line */}
        <label>Contact Us</label>
        {/* eslint-disable-next-line */}
        <div>Questions or concerns about your configured assembly? Please contact us via email or via our live chat where assembly experts are available to help. You can also generate PDF file of your assembly to attach to your email. We&apos;re happy to assist you.</div>
        <div className={styles.buttons}>
          {!!pdfUrl && (
            <a href={mailTo} target="_blank" rel="noreferrer">
              <img src={mailIcon} alt="" />
              {' '}
              Send Email
            </a>
          )}
          {!pdfUrl && (
            <a
              onClick={onEmail}
              href={mailTo}
              target="_blank"
              rel="noreferrer"
            >
              <img src={mailIcon} alt="" />
              {' '}
              Send&nbsp;Email
            </a>
          )}
          {!!pdfUrl && (
            <a href={pdfUrl} target="_blank" rel="noreferrer">
              <img src={printIcon} alt="" />
              {' '}
              Generate PDF with Assembly
            </a>
          )}
          {!pdfUrl && (
            <>
              { /* eslint-disable-next-line */ }
              <a onClick={onDownload} target="_blank" rel="noreferrer">
                <img src={printIcon} alt="" />
                {' '}
                Generate PDF with Assembly
              </a>
            </>
          )}
        </div>
        {!!pdfError && (
          <div>
            <br />
            Warning: Could not generate PDF, try again later
          </div>
        )}
        {isPDFLoading && (
          <div>
            <br />
            Generating PDF...
          </div>
        )}
      </div>
    </>
  );
};
