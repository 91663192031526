export enum LengthType {
  feet = 'feet',
  inches = 'inches',
  centimeters = 'metric',
}

export enum SelectedConfiguration {
  profile = '@hydrapark/profile',
  productType = '@hydrapark/productType',
  hose = '@arg/hose',
  fitting1 = '@arg/fitting1',
  fitting2 = '@arg/fitting2',
}
