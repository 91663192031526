import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Control,
  Controller, FieldPath,
  RegisterOptions,
} from 'react-hook-form';

import Input, { IInputProps } from 'common/components/Input';
import { FallbackFormValues } from 'utils/type-tools';
import { ChangeHandlerType } from '../types';

interface IInputHFProps<TFormValue extends FallbackFormValues> extends IInputProps {
  name: FieldPath<TFormValue>,
  control: Control<TFormValue>,
  rules?: RegisterOptions,
  onChangeHandler?: ChangeHandlerType<TFormValue>,
}

const InputHF = <TFormValue extends FallbackFormValues = {}>({
  name,
  control,
  rules,
  onChangeHandler,
  ...props
}: IInputHFProps<TFormValue>) => (
  <Controller
    name={name}
    control={control}
    rules={{ ...rules }}
    render={({
      field,
      fieldState: { error },
    }) => {
      const strValue = field.value ? field.value as string : '';
      const [valueBuffer, setValueBuffer] = useState<string>(strValue); // eslint-disable-line  react-hooks/rules-of-hooks

      const ref = useRef<HTMLInputElement | null>(null); // eslint-disable-line  react-hooks/rules-of-hooks
      const setRef = useCallback((node: HTMLInputElement) => { // eslint-disable-line  react-hooks/rules-of-hooks
        ref.current = node;
        field.ref(node);
      }, [field.ref]);

      useEffect(() => { // eslint-disable-line  react-hooks/rules-of-hooks
        if (document.activeElement !== ref.current) { // Ignore updates from server while input is in focus
          setValueBuffer(field.value ? field.value as string : '');
        }
      }, [field.value]);

      const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => { // eslint-disable-line  react-hooks/rules-of-hooks
        setValueBuffer(event.target.value);
        field.onChange(event.target.value);
        if (onChangeHandler) {
          onChangeHandler(event.target.value as TFormValue[keyof TFormValue]);
        }
      }, [field.onChange, onChangeHandler]);

      return (
        <Input
          {...props}
          onChange={onChange}
          ref={setRef}
          value={valueBuffer}
          name={name}
          error={error?.message}
        />
      );
    }}
  />
  );

// @ts-ignore
export default InputHF;
