export enum ARGProjectFields {
    hoseApplicationAttribute = 'hoseApplicationAttribute',
    hoseInsideDiameter = 'hoseInsideDiameter',
    hosePressure = 'hosePressure',
    hoseProduct = 'hoseProduct',
    hoseMaterialConstruction = 'hoseMaterialConstruction',
    preselectedHoseProduct = 'preselectedHoseProduct',
    fitting1ConnectionType = 'fitting1ConnectionType',
    fitting1ConnectionSize = 'fitting1ConnectionSize',
    // fitting1Shape = 'fitting1Shape',
    fitting1Product = 'fitting1Product',
    fitting1Gender = 'fitting1Gender',
    fitting2ConnectionType = 'fitting2ConnectionType',
    fitting2ConnectionSize = 'fitting2ConnectionSize',
    // fitting2Shape = 'fitting2Shape',
    fitting2Product = 'fitting2Product',
    fitting2Gender = 'fitting2Gender',
    assemblyLength = 'assemblyLength',
    angleOfRotation = 'angleOfRotation',
    accessories = 'accessories',
    options = 'options',
    testingConfiguration = 'testingConfiguration',
    customerSerialNumber = 'customerSerialNumber',
    requiredComponents = 'requiredComponents',
    fitting2SameAs1 = 'fitting2SameAs1',

    // Note this is a fake field, not existing on server
    tosAgreed = 'tosAgreed',
}
