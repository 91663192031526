import React from 'react';
import {
  Control,
  Controller, FieldPath,
  RegisterOptions,
} from 'react-hook-form';

import Checkbox, { ICheckboxProps } from 'common/components/Checkbox';
import { FallbackFormValues } from 'utils/type-tools';

interface ICheckboxHFProps<TFormValue extends FallbackFormValues = FallbackFormValues> extends ICheckboxProps {
  name: FieldPath<TFormValue>,
  control: Control<TFormValue>,
  rules?: RegisterOptions,
  value: string,
  image?: string,
  onChangeHandler?: (fieldName: string, fieldVal: string, isSelected: boolean) => void,
}

const CheckboxHF = <TFormValue extends FallbackFormValues = FallbackFormValues>({
  name,
  label,
  description,
  control,
  rules,
  children,
  onChangeHandler,
  ...props
}: ICheckboxHFProps<TFormValue>) => (
  <Controller
    name={name}
    control={control}
    rules={{ ...rules }}
    render={({
      field,
      fieldState: { error },
    }) => (
      <Checkbox
        {...props}
        onChange={() => {
          if (onChangeHandler) {
            onChangeHandler(name, props.value, !field.value);
          }
          field.value ? field.onChange('') : field.onChange(props.value);
        }}
        error={error?.message}
        checked={!!field.value}
        name={name}
        label={label}
        description={description}
      >
        {children}
      </Checkbox>
    )}
  />
  );

export default CheckboxHF;
