import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * @PROJECT
 * This is a project specific import. When switching projects, look for every import that is marked with @PROJECT
 */
import FormSwitcher from 'containers/@hydrapark/FormSwitcher';
import 'styles/common.scss';
import { GenericFieldsHandlingLayer } from 'containers/GenericFieldsHandlingLayer';
import { AdditionalDataStorage } from 'containers/AdditionalDataStorage';

const initApp = async () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  const queryClient = new QueryClient();

  root.render(<React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GenericFieldsHandlingLayer>
        <AdditionalDataStorage>
          <FormSwitcher />
        </AdditionalDataStorage>
      </GenericFieldsHandlingLayer>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </React.StrictMode>);
};

initApp()
  .catch((error) => console.error('An error occurred while Application was starting', error)); // eslint-disable-line no-console
