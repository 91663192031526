import React from 'react';

import { CardStatus } from 'common/components/CardNav/constants';

import { ISelectedItemType } from 'common/components/CardNav/types';

import styles from './styles.module.scss';

interface IFulfilledBodyProps {
  selectedItem: ISelectedItemType,
  status: CardStatus,
  defaultImage: string,
}

const FulfilledBody = ({
  selectedItem: { image, title },
  status,
  defaultImage,
}: IFulfilledBodyProps) => (
  <>
    <div className={styles.image}>
      <img
        src={image || defaultImage}
        alt={title}
        role="presentation"
      />
    </div>
    <div className={styles.title}>{title}</div>
    {status === CardStatus.fulfilled && <div className={styles.fulfilledIcon} />}
  </>
);

export default FulfilledBody;
