import { GeneratorFieldType } from 'common/components/FieldGenerator/types';
import { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';
import { useEffect } from 'react';
import { isFieldDisabled } from '../utils/isStatusDisabled';


export const useIsNextStepAvailable = <Key extends string>(
  order: Array<Key>,
  formFieldsNames: Array<Key>,
  fields: ConfiguredRecordType<Key, GeneratorFieldType>,
  setError: () => void,
) => {
  // mb we need to check if there are no any not disabled fields with empty value and not all fields are filled
  useEffect(() => {
    const orderedFormFields = order.filter((fieldName) => formFieldsNames.includes(fieldName));
    const nonSetFieldIndex = orderedFormFields.findIndex((fieldName) => (
      !!fields[fieldName]
      && isFieldDisabled(fields[fieldName])
      && !fields[fieldName]?.value?.selected
    ));
    if (nonSetFieldIndex !== 0
      && !!fields[orderedFormFields[nonSetFieldIndex - 1]]?.value?.selected
      && !isFieldDisabled(fields[orderedFormFields[nonSetFieldIndex - 1]])
    ) {
      setError();
    }
  }, [order, formFieldsNames, fields, setError]);
};

